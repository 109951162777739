import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { propTypes } from '../../util/types';

import css from './UserNav.module.css';

const UserNavComponent = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const bulkUpload = {
    text: <FormattedMessage id="UserNav.bulkUpload" />,
    selected: currentPage === 'BulkUploadPage',
    linkProps: {
      name: 'BulkUploadPage',
    },
  };
  const showBulkUpload =
    !!currentUser?.attributes?.profile?.publicData?.bulkUpload !== null &&
    !!currentUser?.attributes?.profile?.publicData?.bulkUpload === true;

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    showBulkUpload && (currentUser?.attributes?.profile?.publicData?.bulkUpload ?? false)
      ? {
          text: <FormattedMessage id="UserNav.bulkUpload" />,
          selected: currentPage === 'BulkUploadPage',
          linkProps: {
            name: 'BulkUploadPage',
          },
        }
      : null,
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ].filter(tab => tab !== null);

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
